
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { Inhibitor } from "../../models/reports/inhibitor";
import { formatFinancialAmounts } from '../../utils/format'

export default Vue.extend({
  name: "Inhibitors",
  data: () => ({
    inhibitorData: [] as Inhibitor[],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("basic.country"),
          value: "country",
        },
        {
          text: `${i18n.t("reports.agsreports.hemophilia")} A ${i18n.t(
            "reports.agsreports.activeinhibitors"
          )}`,
          value: "hemophilia_A_with_Inhibitors_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("reports.agsreports.hemophilia")} A ${i18n.t(
            "reports.agsreports.newcasesinhibitors"
          )}`,
          value: "hemophilia_A_New_Inhibitors_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("reports.agsreports.hemophilia")} B ${i18n.t(
            "reports.agsreports.activeinhibitors"
          )}`,
          value: "hemophilia_B_with_Inhibitors_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("reports.agsreports.hemophilia")} B ${i18n.t(
            "reports.agsreports.newcasesinhibitors"
          )}`,
          value: "hemophilia_B_New_Inhibitors_Total",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
      ];
    },
  },
  methods: {
    getInhibitorData(year: number) {
      this.isLoading = true;
      axios
        .get(`Report/WFH/Inhibitors?year=${year}`)
        .then((res) => {
          this.inhibitorData = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`Report/WFH/Inhibitors/export?year=${this.selectedSurvey.year}`, {
          responseType: "blob",
        })
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.inhibitors")}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getInhibitorData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getInhibitorData(this.selectedSurvey.year);
    },
  },
});
